<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/store/store' }">门店列表</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <el-tabs v-model="activeName" @tab-click="onTabs">
                <el-tab-pane label="基本信息" name="info">
                    <Info v-if="info" v-on:toedit="()=>{this.info = !this.info}"/>
                    <Edit v-if="!info" v-on:toinfo="()=>{this.info = !this.info}"/>
                </el-tab-pane>
                <el-tab-pane v-if="is_auth('store.lesson.getlist')" label="门店课程" name="lesson">
                    <Lesson ref="lesson"/>
                </el-tab-pane>
                <el-tab-pane v-if="is_auth('store.classroom.getlist')" label="门店教室" name="classroom">
                    <Classroom ref="classroom"/>
                </el-tab-pane>
                <el-tab-pane label="门店评价" name="comment">门店评价</el-tab-pane>
                <el-tab-pane v-if="is_auth('store.coach.getlist')" label="门店教练" name="coach">
                    <Coach ref="coach"/>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import Info from './page/Info'
import Edit from './page/Edit'
import Lesson from './page/Lesson'
import Classroom from './page/Classroom'
import Coach from './page/Coach'

export default {
    components: {
        Info,
        Edit,
        Lesson,
        Classroom,
        Coach,
    },
    data() {
        return {
            info: true,
            activeName: 'info'
        }
    },
    // 创建
    created() {

    },
    // 安装
    mounted() {
        // 判断是否直接跳转到编辑页面
        if (this.$route.query.toedit == 1) {
            this.info = false
        }
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 点击切换页面
        onTabs(tab) {
            if (tab.paneName === 'lesson') {        // 点击门店课程
                this.$refs.lesson.init();
            }
            if (tab.paneName === 'classroom') {     // 点击门店教室
                this.$refs.classroom.init();
            }
            if (tab.paneName === 'coach') {     // 点击门店教练
                this.$refs.coach.init();
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
